
/deep/.el-button--default:hover{
    border-color: #FD4446;
    color: #FD4446;
    background: #ffffff;
}
.PlanStepSucceed{
    height: calc(100vh - 400px);
    padding-top: 104px;
    padding-bottom: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .iconfont{
        font-size: 60px;
        color: #20D08C;
    }
    span{
        color: #333333;
        font-size: 24px;
        font-weight: 500;
        margin-top: 20px;
    }
    .PlanStepSucceedBtn{
        margin-top: 40px;
    }
    .ckBtn{
        background-color: #FD4446;
        border-color: #FD4446;
        color: #ffffff;
    }
}
